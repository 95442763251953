import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import '../App.css';
import SideMenu from '../component/SideMenu';
import Header from '../component/Header';
import CharacterSelector from '../component/CharacterSelector';
import { getUserProfile, updateUserProfile } from '../Api/marketplace_api';
import XverseWalletConnect from '../component/XverseWalletConnect';
import WalletOrdinalsItems from '../component/WalletOrdinalsItems';
import WalletOrdinalsLands from '../component/WalletOrdinalsLands';
import WalletOrdinalsSkills from '../component/WalletOrdinalsSkills';

function Profile() {
	const { playfabId } = useSelector(state => state.login);
	const { xverseOrdinalsAddr } = useSelector(state => state.wallet);
	const [zbitWalletInfo] = useState(null); // Initialize zbit wallet info
	const [profile, setProfile] = useState([]);
	const [isShowUpdateModal, setIsShowUpdateModal] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [walletAddress, setWalletAddress] = useState('');

	const refresh = useCallback(() => {
		getUserProfile(playfabId).then((result) => {
			setProfile(result);
			setWalletAddress(result.walletAddress);
		})
			.catch(error => {
				console.log("error in getUserProfile:", error);
			});
	}, [playfabId]);

	useEffect(() => {
		refresh();
	}, [refresh]);

	const handleUpdate = async () => {
		setIsSaving(true);
		updateUserProfile(playfabId, walletAddress).then((result) => {
			setIsSaving(false);
			refresh();
			setIsShowUpdateModal(false);
			toast("Success! updated");
		})
			.catch(error => {
				console.log("error in updateUserProfile:", error);
				setIsSaving(false);
				toast("Error! Invalid parameter");
			});
	};

	const handleClose = () => {
		setIsShowUpdateModal(false);
	};

	return (
		<div className="App">
			{/* Side Menu */}
			<SideMenu zbitWalletInfo={zbitWalletInfo} /> {/* Pass zbit wallet info as a prop to SideMenu */}

			{/* Main Content */}
			<div className="main-content">
				<Header />

				<div className="row m-0 p-0">
					<div className="col-12 text-end p-0 pe-4">
						<CharacterSelector />
					</div>
				</div>

				<div className='mt-4 p-3'>
					<h5>Your Profile</h5>
					<div className='mt-5'>
						<p>Player Name:</p>
						<div className='row m-0 p-0 mt-2'>
							<div className='col-sm-9 p-0 pe-2'>
								<input className='common-input-1' value={profile.playerName ? profile.playerName : ''} style={{ width: '100%' }} readOnly />
							</div>
						</div>

						<p className='mt-3'>Email Address:</p>
						<div className='row m-0 p-0 mt-2'>
							<div className='col-sm-9 p-0 pe-2'>
								<input className='common-input-1' value={profile.emailAddress ? profile.emailAddress : ''} style={{ width: '100%' }} readOnly />
							</div>
						</div>

						<p className='mt-3'>Wallet Address:</p>
						<div className='row m-0 p-0 mt-2 mb-5'>
							<div className='col-sm-9 p-0 pe-2'>
								<input className='common-input-1' value={profile.walletAddress ? profile.walletAddress : ''} style={{ width: '100%' }} readOnly />
							</div>
							<div className='col-sm-3 p-0'>
								<button className='button-gradient-1' onClick={() => { setIsShowUpdateModal(true) }}>UPDATE</button>
							</div>
						</div>

						<hr />
						<XverseWalletConnect />
						{
							xverseOrdinalsAddr && xverseOrdinalsAddr !== '' ? (
								<>
									<h6 className='mt-5'>Ordinals Items:</h6>
									<WalletOrdinalsItems />

									<h6 className='mt-5'>Ordinals Land Items:</h6>
									<WalletOrdinalsLands />

									<h6 className='mt-5'>Ordinals Skill Items:</h6>
									<WalletOrdinalsSkills />
								</>
							) : (
								<></>
							)
						}

					</div>
				</div>

				<Modal show={isShowUpdateModal} onHide={handleClose} backdrop={true} className='modal-cm'>
					<Modal.Header closeButton>
						<Modal.Title>Update</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							<label className='mt-3 mb-1'>Wallet Address:</label>
							<input
								type="text"
								className="common-input-1 d-block"
								style={{ width: '100%' }}
								placeholder="Wallet Address"
								value={walletAddress}
								onChange={(e) => { setWalletAddress(e.target.value) }}
							/>
						</div>
						<div className='text-center mt-4'>
							<button
								className='button-gradient-1'
								disabled={isSaving}
								onClick={handleUpdate}
							>
								{isSaving ? 'Wait...' : 'UPDATE'}
							</button>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		</div>
	);
}

export default Profile;
