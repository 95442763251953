import React, { useState, useEffect } from 'react';
import { Modal, Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { fetchCharacters, withdrawZbitLoot } from '../Api/marketplace_api';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import { GetRecommendedFees } from '../Api/mempoolApi';
import XverseWalletConnect from '../component/XverseWalletConnect';

const ZbitLootItem = ({ zbitLootItem, updateEvent }) => {
	const [itemProperty, setItemProperty] = useState(null);
	const [imageSrc, setImageSrc] = useState();
	const [claimProfile, setClaimProfile] = useState([]);
	const [isShowClaimModal, setIsShowClaimModal] = useState(false);
	const [characters, setCharacters] = useState([]);
	const [selectedCharacter, setSelectedCharacter] = useState(null);
	const [isSaving, setIsSaving] = useState(false);
	const [ownerCharacter, setOwnerCharacter] = useState(null);

	const [withdrawAmount, setWithdrawAmount] = useState(0);
	const [feeData, setFeeData] = useState(null);
	const [mempoolFee, setMempoolFee] = useState(0);
	const [txId, setTxId] = useState('');
	const [withdrawFee, setWithdrawFee] = useState(0);
	const [isPending, setIsPending] = useState(false);
	const [withdrawTxId, setWithdrawTxId] = useState('');

	const { playfabId, currentCharacter } = useSelector(state => state.login);

	const { xverseOrdinalsAddr, xversePaymentAddr } = useSelector(state => state.wallet);

	useEffect(() => {
		if (playfabId) {
			fetchCharacters(playfabId)
				.then(caracters => {
					setCharacters(caracters);
				})
				.catch(error => {
					setCharacters([]);
				});
		}
	}, [playfabId]);

	useEffect(() => {
		GetRecommendedFees().then((feeData) => {
			setFeeData(feeData);
			setMempoolFee(feeData.hourFee);
		})
			.catch(error => { toast(`getting mempool fee data is failed. (${error})`) })
	}, []);

	useEffect(() => {
		setWithdrawFee((mempoolFee * 220 + 2500) / 100000000);
	}, [mempoolFee]);

	useEffect(() => {
		try {
			setImageSrc(`https://ordiscan.com/content/${zbitLootItem.inscriptionId}`);
		}
		catch {
			setImageSrc(`/images/150.png`);
		}
	}, [itemProperty]);

	const handleWithdraw = () => {
		if (txId === '') {
			toast('Error: Transaction ID is required.');
			return false;
		}

		setIsSaving(true);
		withdrawZbitLoot(playfabId, currentCharacter.characterId, zbitLootItem.id, mempoolFee, xverseOrdinalsAddr, xversePaymentAddr, txId).then(txId => {
				toast('Successfully sent your withdrawal request!');
				setWithdrawTxId(txId);
				updateEvent();
				setIsPending(false);
			}).catch(error => {
				toast(`Error: ${error}`);
				setIsPending(false);
			});
	}

	const handleClose = () => {
		setIsShowClaimModal(false);
	}

	const handleClickAddress = (event) => {
		event.target.select();
		document.execCommand('copy');
		toast('Copied Address');
	};

	return (
		<div className='row m-0 p-0 mb-2 div-gradient-border'>
			<div className='col-sm-2'>
				<div className='d-inline-block me-3' style={{ verticalAlign: 'middle' }}>
					<div className='td-item-image'>
						<img src={imageSrc} alt='item' />
					</div>
				</div>
				{
					itemProperty && (
						<div className='d-inline-block' style={{ verticalAlign: 'middle' }}>
							<span style={{ fontSize: '9px' }}>{itemProperty.itemClass ? itemProperty.itemClass : ''}</span><br />
							<span style={{ fontSize: '14px', fontWeight: '600' }}>{itemProperty.displayName ? itemProperty.displayName : ''}</span>
						</div>
					)
				}
			</div>
			<div className='col-sm-3'>
				<div className='mt-2'>
					<span style={{ fontSize: '9px' }}>INSCRIPTION ID</span><br />
					<span style={{ fontSize: '14px' }} title={zbitLootItem.inscriptionId}>
						<a href={'https://ordinals.com/inscription/' + zbitLootItem.inscriptionId} target='_blank'> {zbitLootItem.inscriptionId.substr(0, 4) + '...' + zbitLootItem.inscriptionId.substr(-4)} </a>
					</span>
				</div>
			</div>
			<div className='col-sm-5'>
				<div className='mt-2'>
					<span style={{ fontSize: '9px' }} className='d-none'>OWNER</span><br />
					<span style={{ fontSize: '14px' }} className='d-none'>{currentCharacter ? currentCharacter.characterName : ''}</span>
				</div>
			</div>
			<div className='col-sm-2 text-end'>
				<button className='button-gradient-1 mt-2' onClick={() => { setIsShowClaimModal(true) }}>CLAIM</button>
			</div>

			{
				zbitLootItem && (
					<Modal show={isShowClaimModal} onHide={handleClose} backdrop={true} className='modal-cm modal-lg'>
						<Modal.Body>
							<h5 className='text-center'>CLAIM</h5>
							<div className='text-center mt-5' style={{ fontSize: '12px' }}>
								Inscription ID
								<div title={zbitLootItem.inscriptionId}>{zbitLootItem.inscriptionId.substr(0, 8) + '...' + zbitLootItem.inscriptionId.substr(-8)}</div>
								<div className='mt-3'>
									<XverseWalletConnect style={{ width: '100%' }} />
								</div>

								{
									xverseOrdinalsAddr && xverseOrdinalsAddr !== '' ? (
										<>
											<div className="row mt-1 p-3" style={{ fontSize: '9px' }}>
												{
													feeData && (
														<>
															<div className="col">
																<Form.Check
																	label={
																		<>
																			Fastest <br /> {`${feeData.fastestFee} sat/vb`}
																		</>
																	}
																	name="fee_check" type="radio" id={`fastestFee`}
																	onChange={() => { setMempoolFee(feeData.fastestFee) }}
																	checked={mempoolFee === feeData.fastestFee}
																/>
															</div>
															<div className="col">
																<Form.Check
																	label={
																		<>
																			Half Hour <br /> {`${feeData.halfHourFee} sat/vb`}
																		</>
																	}
																	name="fee_check" type="radio" id={`halfHourFee`}
																	onChange={() => { setMempoolFee(feeData.halfHourFee) }}
																	checked={mempoolFee === feeData.halfHourFee}
																/>
															</div>
															<div className="col">
																<Form.Check
																	label={
																		<>
																			Hour <br /> {`${feeData.hourFee} sat/vb`}
																		</>
																	}
																	name="fee_check" type="radio" id={`hourFee`}
																	onChange={() => { setMempoolFee(feeData.hourFee) }}
																	checked={mempoolFee === feeData.hourFee}
																/>
															</div>
															<div className="col">
																<Form.Check
																	label={
																		<>
																			Economy <br /> {`${feeData.economyFee} sat/vb`}
																		</>
																	}
																	name="fee_check" type="radio" id={`economyFee`}
																	onChange={() => { setMempoolFee(feeData.economyFee) }}
																	checked={mempoolFee === feeData.economyFee}
																/>
															</div>
															<div className="col">
																<Form.Check
																	label={
																		<>
																			Minimum <br /> {`${feeData.minimumFee} sat/vb`}
																		</>
																	}
																	name="fee_check" type="radio" id={`minimumFee`}
																	onChange={() => { setMempoolFee(feeData.minimumFee) }}
																	checked={mempoolFee === feeData.minimumFee}
																/>
															</div>
														</>
													)
												}
											</div>

											<label className='box-subtitle mt-3'>Send <b style={{ fontSize: '10px' }}>{withdrawFee} BTC</b> (Transaction Fee) to address below and submit the transaction ID after confirmed.</label>
											{
												xverseOrdinalsAddr && (
													<label className='box-subtitle mt-3 text-warning'>
														The transaction fee must be sent from your <b style={{ fontSize: '10px' }}>Xverse Wallet</b> addresses. <br />
														<a href={'https://mempool.space/address/' + xverseOrdinalsAddr} target='_blank' rel="noreferrer">{xverseOrdinalsAddr}</a> <br />
														<a href={'https://mempool.space/address/' + xversePaymentAddr} target='_blank' rel="noreferrer">{xversePaymentAddr}</a>
													</label>
												)
											}

											<input
												type='text'
												className='common-input-1 mt-3'
												style={{ width: '100%', cursor: 'pointer' }}
												placeholder='Transaction ID'
												autoComplete="off"
												name="depositAddress"
												value={'bc1ph0v3zn64ykrkmfzs83ewnw0rj7r67795x3l6d5tufh2zud3x8tuqkpu6hk'}
												readOnly
												onClick={(e) => { handleClickAddress(e) }}
											/>

											<input
												type='text'
												className='common-input-1 mt-3'
												style={{ width: '100%' }}
												placeholder='Transaction ID'
												autoComplete="off"
												name="amount"
												value={txId}
												onChange={(e) => { setTxId(e.target.value) }}
											/>
											<button className='auth-button mt-3' onClick={handleWithdraw} disabled={isPending}>{isPending ? 'Wait...' : 'CLAIM'}</button>

										</>
									) : (
										<label className='box-subtitle mt-3'>Connect to Xverse wallet to claim.</label>
									)
								}

								{
									withdrawTxId !== '' && (
										<div className='mt-4 text-center'>
											<p>Show on wallet</p>
											<a href={`https://mempool.space/tx/${withdrawTxId}`}>{withdrawTxId}</a>
										</div>
									)
								}
							</div>
						</Modal.Body>
					</Modal>
				)
			}

		</div>
	);
}

export default ZbitLootItem;