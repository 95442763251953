import React, { useState } from 'react';
import '../App.css';
import MarketPlaceUI from '../component/MarketPlaceUI';
import SideMenu from '../component/SideMenu';
import Header from '../component/Header';

function MarketPlaceORD() {
  const [zbitWalletInfo] = useState(null); // Initialize zbit wallet info

  return (
    <div className="App">
      {/* Side Menu */}
      <SideMenu zbitWalletInfo={zbitWalletInfo} /> {/* Pass zbit wallet info as a prop to SideMenu */}
      {/* Main Content */}
      <div className="main-content">
        {/* Header */}
        <Header />    
        {/* Marketplace Content */}
        <h5 className='m-3 mt-4'>Comming Soon!</h5>
        {/* <MarketPlaceUI /> */}
      </div>
    </div>
  );
}

export default MarketPlaceORD;
