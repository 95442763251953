const initialState = {
	walletAddr: null,
	xverseOrdinalsAddr: null,
	xverseOrdinalsSign: null,
	xversePaymentAddr: null,
	xversePaymentSign: null,
	xverseSignatureMsg: "",
	zbitData: null,
	creditAmount: 0,
	onigiriAmount: 0,
};

const walletReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_ADDRESS':
			return {
				...state,
				walletAddr: action.payload
			};
		case 'SET_XVERSE_ORDINALS_ADDRESS':
			return {
				...state,
				xverseOrdinalsAddr: action.payload
			};
		case 'SET_XVERSE_ORDINALS_SIGNATURE':
			return {
				...state,
				xverseOrdinalsSign: action.payload
			};
		case 'SET_XVERSE_PAYMENT_ADDRESS':
			return {
				...state,
				xversePaymentAddr: action.payload
			};
		case 'SET_XVERSE_PAYMENT_SIGNATURE':
			return {
				...state,
				xversePaymentSign: action.payload
			};
		case 'SET_XVERSE_SIGNATURE_MESSAGE':
			return {
				...state,
				xverseSignatureMsg: action.payload
			};
		case 'SET_DATA':
			return {
				...state,
				zbitData: action.payload
			};
		case 'SET_CREDIT_AMOUNT':
			return {
				...state,
				creditAmount: action.payload
			};
		case 'SET_ONIGIRI_AMOUNT':
			return {
				...state,
				onigiriAmount: action.payload
			};
		default:
			return state;
	}
};

export default walletReducer;