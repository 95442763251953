import React, { useState } from 'react';
import '../App.css';
import { toast } from 'react-toastify';

import SideMenu from '../component/SideMenu';
import Header from '../component/Header';
import { useSelector } from 'react-redux';

import { addUserCredit } from '../Api/marketplace_api';
import CreditBox from '../component/CreditBox';
import XverseWalletConnect from '../component/XverseWalletConnect';

function Refill() {
	const { playfabId, currentCharacter } = useSelector(state => state.login);
	const { xverseOrdinalsAddr, xversePaymentAddr, creditAmount, onigiriAmount } = useSelector(state => state.wallet);
	const [refillAmount, setRefillAmount] = useState(100);
	const [txId, setTxId] = useState('');
	const [isPending, setIsPending] = useState(false);

	const handleRefill = () => {		
		if (txId === '') {
			toast('Error: Transaction ID is required.');
			return false;
		}

		setIsPending(true);

		addUserCredit(playfabId, currentCharacter.characterId, xverseOrdinalsAddr, txId).then(value => {
			toast('Success!');
			// refreshCreditAmount();
			setIsPending(false);
		}).catch(error => {
			toast(`Error: ${error}`);
			setIsPending(false);
		});
	};

	const handleRefillByRunes = async () => {
		/*
		if (refillAmount > 0) {
			const response = await Wallet.request('runes_transfer', [
				{
					runeName: 'ZBIT•BLUE•BITCOIN',
					amount: refillAmount,
					address: 'bc1psv507rkh97q524kqrtrwjnzuv09jgppc78xurdvzcvfypn23d8ssggfp08',
				}]
			);

			if (response.status === "success") {
				//console.log(response);
			} else {
				toast("sending runes is failed.");
			}
		}
		*/
	};

	const handleClickAddress = (event) => {
		event.target.select();
		document.execCommand('copy');
		toast('Copied Address');
	};

	return (
		<div className='App'>
			<SideMenu />
			<div className="main-content">
				<Header />
				<div className='auth-page'>
					<div className="auth-content">
						<div className="auth-box">
							<label className='box-title'>YOUR CURRENT CREDIT</label>
							<div className='text-center'>
								<CreditBox />
							</div>
							<div className='mt-3'>
								<XverseWalletConnect style={{ width: '100%' }} />
							</div>
							{
								xverseOrdinalsAddr && xverseOrdinalsAddr !== '' ? (
									<>
										<label className='box-subtitle mt-3'>Send your zbit (<a href="https://unisat.io/runes/detail/ZBIT%E2%80%A2BLUE%E2%80%A2BITCOIN">Runes</a>) to address below and submit the transaction ID (Transaction should be confirmed).</label>
										
										{
											xverseOrdinalsAddr && (
												<label className='box-subtitle mt-3 text-warning'>
													The ZBIT must be sent from your <b style={{ fontSize: '10px' }}>Xverse Wallet</b> addresse. <br />
													<a href={'https://mempool.space/address/' + xverseOrdinalsAddr} target='_blank' rel="noreferrer">{xverseOrdinalsAddr}</a>
												</label>
											)
										}

										<input
											type='text'
											className='common-input-1 mt-3'
											style={{ width: '100%', cursor: 'pointer' }}
											placeholder='Transaction ID'
											autoComplete="off"
											name="depositAddress"
											value={'bc1psv507rkh97q524kqrtrwjnzuv09jgppc78xurdvzcvfypn23d8ssggfp08'}
											readOnly
											onClick={(e) => { handleClickAddress(e) }}
										/>

										<input
											type='text'
											className='common-input-1 mt-3'
											style={{ width: '100%' }}
											placeholder='Transaction ID'
											autoComplete="off"
											name="amount"
											value={txId}
											onChange={(e) => { setTxId(e.target.value) }}
										/>
										<button className='auth-button mt-3' onClick={handleRefill}  disabled={isPending}>{isPending ? 'Wait...' : 'REFILL'}</button>
										<div className='row m-0 p-0 mt-2 d-none'>
											<input
												type='text'
												className='common-input-1 mt-3'
												style={{ width: '100%' }}
												placeholder='Transaction ID'
												autoComplete="off"
												name="amount"
												value={refillAmount}
												onChange={(e) => { setRefillAmount(e.target.value) }}
											/>
											<div className='col-2 p-1 ps-0'>
												<button className='auth-button' onClick={() => { setRefillAmount(100) }}>100</button>
											</div>
											<div className='col-2 p-1'>
												<button className='auth-button' onClick={() => { setRefillAmount(200) }}>200</button>
											</div>
											<div className='col-2 p-1'>
												<button className='auth-button' onClick={() => { setRefillAmount(500) }}>500</button>
											</div>
											<div className='col-2 p-1'>
												<button className='auth-button' onClick={() => { setRefillAmount(1000) }}>1k</button>
											</div>
											<div className='col-2 p-1'>
												<button className='auth-button' onClick={() => { setRefillAmount(5000) }}>5k</button>
											</div>
											<div className='col-2 p-1 pe-0'>
												<button className='auth-button' onClick={() => { setRefillAmount(10000) }}>10k</button>
											</div>

											<button className='auth-button mt-3' onClick={handleRefillByRunes}>REFILL By Runes</button>
										</div>
									</>
								) : (
									<label className='box-subtitle mt-3'>Connect to Xverse wallet to refill ZBIT.</label>
								)
							}


						</div>
					</div>
				</div>
			</div>
		</div >
	);
}

export default Refill;
