import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Dropdown, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
// import { persistStore, store } from 'redux-persist';
import '../assets/css/style.css';
import { fetchCharacters } from '../Api/marketplace_api';

const CharacterSelector = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { playfabId, currentCharacter } = useSelector(state => state.login);
    const [characters, setCharacters] = useState([]);
    const [isShowLoginModal, setIsShowLoginModal] = useState(false);

    const handleSelectCharacter = async (character) => {
        dispatch({ type: 'SET_CURRENT_CHARACTER', payload: character });
    };
    const handleLogout = async () => {
        dispatch({ type: 'SET_LOGIN_PLAYFAB_ID', payload: "" });
        dispatch({ type: 'SET_CURRENT_CHARACTER', payload: "" });

        navigate('/');
    };

    const handleOpenProfilePage = async () => {
        navigate('/profile');
    }

    const [accountInfo, setAccountInfo] = useState({ username: '', password: '' });
    const handleChange = (e) => {
        setAccountInfo({ ...accountInfo, [e.target.name]: e.target.value });
    };

    const handleLogin = () => {
        // console.log(accountInfo);
        if (accountInfo.username.length === 0 || accountInfo.password.length === 0) {
            toast('Error: Enter username or password.');
            return false;
        }
        window.PlayFab.settings.titleId = 'FA008';
        try {
            if (accountInfo.username.endsWith(".com")) {
                const request = { Email: accountInfo.username, Password: accountInfo.password };
                window.PlayFab.ClientApi.LoginWithEmailAddress(request, (result, error) => {
                    if (error) {
                        toast(error.errorMessage);
                    } else {
                        console.log(result);
                        if (result.code === 200) {
                            dispatch({ type: 'SET_LOGIN_PLAYFAB_ID', payload: result.data.PlayFabId });
                            setIsShowLoginModal(false);
                        }
                        else {
                            toast('Error: ' + result.error);
                        }
                    }
                });
            }
            else {
                const request = { Username: accountInfo.username, Password: accountInfo.password };
                window.PlayFab.ClientApi.LoginWithPlayFab(request, (result, error) => {
                    if (error) {
                        toast(error.errorMessage);
                    } else {
                        // console.log(result);
                        if (result.code === 200) {
                            dispatch({ type: 'SET_LOGIN_PLAYFAB_ID', payload: result.data.PlayFabId });
                            setIsShowLoginModal(false);
                        }
                        else {
                            toast('Error: ' + result.error);
                        }
                    }
                });
            }
        } catch (error) {
            console.log(error)
            toast('Error: ' + error?.message);
        }
    };

    useEffect(() => {
        if (playfabId) {
            fetchCharacters(playfabId)
                .then(caracters => {
                    setCharacters(caracters);
                    if (!currentCharacter && caracters.length > 0) {
                        dispatch({ type: 'SET_CURRENT_CHARACTER', payload: caracters[0] });
                    }
                })
                .catch(error => {
                    setCharacters([]);
                });
        }
    }, [playfabId]);

    return (
        <>
            {playfabId ? <div className='character-selector'>
                <Dropdown>
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                        <span className='me-1'>{currentCharacter ? currentCharacter.characterName : ''}</span>
                        <div className='character-circle'>
                            <img src='/images/character.png' className='character-icon' alt='character' />
                        </div>
                    </Dropdown.Toggle>
                    {
                        characters?.length > 0 &&
                        (<Dropdown.Menu>
                            {
                                characters.map((character, index) => (
                                    <Dropdown.Item key={character.characterId} onClick={() => { handleSelectCharacter(character) }}>{character.characterName}</Dropdown.Item>
                                ))
                            }
                            <Dropdown.Item onClick={() => { handleOpenProfilePage() }} style={{ borderTop: 'solid 1px #555555' }}>Profile</Dropdown.Item>
                            <Dropdown.Item onClick={() => { handleLogout() }}>Logout</Dropdown.Item>
                        </Dropdown.Menu>)
                    }
                </Dropdown>
            </div> : <button className='button-gradient-1' onClick={() => setIsShowLoginModal(true)}>Login</button>}

            <Modal show={isShowLoginModal} onHide={() => setIsShowLoginModal(false)} backdrop={true} className='modal-cm modal-auth'>
                <Modal.Body>
                    <div className="auth-content">
                        <div className="auth-box" style={{ maxWidth: '450px' }}>
                            <label className='box-title'>Login to PWER</label>
                            <label className='box-subtitle'>EPIC WEB3 GAMING</label>
                            <input
                                type='text'
                                className='common-input-1 mt-3'
                                style={{ width: '100%' }}
                                placeholder='USER NAME'
                                autoComplete="off"
                                name="username"
                                value={accountInfo.name}
                                onChange={handleChange}
                            />
                            <input
                                type='password'
                                className='common-input-2 mt-2'
                                style={{ width: '100%' }}
                                placeholder='PASSWORD'
                                autoComplete="new-password"
                                name="password"
                                value={accountInfo.password}
                                onChange={handleChange}
                            />
                            <div className="d-flex mt-3">
                                <Button variant='secondary' className='auth-button me-1' onClick={handleLogin}>LOGIN</Button>
                                <Button variant='danger' className='auth-button ms-1' onClick={() => setIsShowLoginModal(false)}>ClOSE</Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>

    );
}

export default CharacterSelector;
